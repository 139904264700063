const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

document.addEventListener("click", function(event) {
  const button = event.target.closest(`.platforma-site-header [aria-controls="menu"]`);
  if (!button) {
    return;
  }

  const state = button.getAttribute("aria-expanded");
  const isOpen = state === "true";
  const mobileMenu = $("header[role='banner'].platforma-site-header");

  button.setAttribute("aria-expanded", isOpen ? "false" : "true");
  button.innerText = isOpen ? "Menu" : "Close";
  mobileMenu.classList.toggle("menu-open");
  $("body").classList.toggle("overflow-hidden");
});
