const pauseButton = document.getElementById("videoPause");
const playButton = document.getElementById("videoPlay");
const video = document.getElementById("mastheadVideo");

if (playButton) {
  
  playButton.addEventListener("click", function(event) {
    playButton.classList.add("hidden");
    pauseButton.classList.remove("hidden");
    video.play();
  });

  pauseButton.addEventListener("click", function(event) {
    pauseButton.classList.add("hidden");
    playButton.classList.remove("hidden");
    video.pause();
  });

};
